//======================================================================
// variable
//======================================================================
// imgディレクトリまでのパス
$img_dir: "../img";

// コンテンツ幅
$content_width: 1200px;

// SP時の左右の余白（片方）
$sp_side_padding: 15px;

//=================================
// breakpoint
//=================================
// 下記2つの変数の間がタブレット表示
// タブレット表示なしの場合は2つの変数の値を「1px差」で設定すること

// $pc_bp以上がPC表示
$pc_bp: 1025px;

// $sp_bp以下がSP表示
$sp_bp: 767px;

//=================================
// media query
//=================================
@mixin mq($min_width, $max_width) {
  @media screen and (min-width: $min_width + px) and (max-width: $max_width + px) {
    @content;
  }
}
@mixin mq_min($width) {
  @media screen and (min-width: $width + px) {
    @content;
  }
}
@mixin mq_max($width) {
  @media screen and (max-width: $width + px) {
    @content;
  }
}

// device
@mixin pc {
  @media screen and (min-width: $pc_bp) {
    @content;
  }
}
@mixin sp {
  @media screen and (max-width: $sp_bp) {
    @content;
  }
}
@mixin tab {
  @media screen and (min-width: $sp_bp + 1px) and (max-width: $pc_bp - 1px) {
    @content;
  }
}
@mixin pc_tab {
  @media screen and (min-width: $sp_bp + 1px) {
    @content;
  }
}
@mixin sp_tab {
  @media screen and (max-width: $pc_bp - 1px) {
    @content;
  }
}
@mixin pc_content {
  @media screen and (min-width: $pc_bp) and (max-width: $content_width) {
    @content;
  }
}

$color_1: #02143a;
$color_2: #242424;
$color_3: #365190;

.u_ntxt_1 {
  font-size: 14px;
  line-height: 22px;
}
.u_ntxt_2 {
  font-size: 16px;
  line-height: 24px;
}
.u_ntxt_3 {
  font-size: 40px;
  line-height: 58px;

  @include sp {
    font-size: 30px;
    line-height: 43px;
  }
}
.u_ntxt_4 {
  font-size: 18px;
  line-height: 30px;

  @include sp {
    font-size: 16px;
    line-height: 28px;
  }
}
.u_ntxt_5 {
  font-size: 14px;
  line-height: 24px;
}
.u_ntxt_6 {
  font-size: 60px;
  line-height: 58px;

  @include sp {
    font-size: 46px;
    line-height: 50px;
  }
}
.u_ntxt_7 {
  font-size: 20px;
  line-height: 30px;

  @include sp {
    font-size: 16px;
    line-height: 26px;
  }
}
.u_ntxt_8 {
  font-size: 40px;
  line-height: 58px;

  @include sp {
    font-size: 34px;
    line-height: 43px;
  }
}
.u_ntxt_9 {
  font-size: 16px;
  line-height: 22px;
}
.u_ntxt_10 {
  font-size: 30px;
  line-height: 44px;

  @include sp {
    font-size: 26px;
    line-height: 36px;
  }
}
.u_ntxt_11 {
  font-size: 20px;
  line-height: 30px;

  @include sp {
    font-size: 16px;
    line-height: 22px;
  }
}
.u_ntxt_12 {
  font-size: 18px;
  line-height: 30px;

  @include sp {
    font-size: 14px;
    line-height: 22px;
  }
}
.u_ntxt_13 {
  font-size: 24px;
  line-height: 30px;

  @include sp {
    font-size: 18px;
    line-height: 22px;
  }
}
.u_ntxt_14 {
  font-size: 50px;
  line-height: 58px;

  @include sp {
    font-size: 40px;
    line-height: 44px;
  }
}
.u_ntxt_15 {
  font-size: 18px;
  line-height: 30px;
}
.u_ntxt_16 {
  font-size: 16px;
  line-height: 26px;

  @include sp {
    font-size: 14px;
    line-height: 22px;
  }
}
.u_ntxt_17 {
  font-size: 24px;
  line-height: 36px;

  @include sp {
    font-size: 20px;
    line-height: 32px;
  }
}
.u_ntxt_18 {
  font-size: 20px;
  line-height: 28px;
}
.u_ntxt_19 {
  font-size: 30px;
  line-height: 40px;

  @include sp {
    font-size: 20px;
    line-height: 30px;
  }
}

.u_ncolor_1 {
  color: $color_3;
}
.u_ncolor_2 {
  color: #666;
}
.u_ncolor_3 {
  color: #fff;
}
.u_ncolor_4 {
  color: #ffd800;
}
.u_ncolor_5 {
  color: $color_1;
}
.u_ncolor_6 {
  color: #7fd983;
}

.u_black {
  font-weight: 900;
}

.u_bg_1 {
  background: #365190;
}
.u_bg_2 {
  background: transparent linear-gradient(6deg, #eabe00 0%, #ffedad 45%, #ffd103 50%, #ffe367 100%) 0 0 no-repeat padding-box;
}
.u_bg_3 {
  background: #ffd800;
}
.u_bg_4 {
  background: #fafafa;
}

/* =================================
 paragraph margin
================================= */
.u_paragraph {
  & + & {
    margin: 20px 0 0;
  }
}

/* =================================
 component
================================= */
.c-nbtn_1 {
  position: relative;
  z-index: 4;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 320px;
  height: 60px;
  border: 2px solid #365190;
  border-radius: 30px;
  background-color: #fff;
  color: #365190;
  text-decoration: none;
  font-size: 18px;
  transition: border-color .2s, background-color 0s 0s;
  &::before {
    position: absolute;
    top: -5%;
    left: calc(-100% - 11px);
    z-index: 1;
    width: calc(100% + 15px);
    height: 110%;
    background-color: #02143a;
    content: "";
    transition: left .2s;

    clip-path: polygon(0 100%, 0% 0%, 100% 0%, 94% 100%);
  }
  &::after {
    position: absolute;
    top: calc(50% - 5px);
    right: 28px;
    z-index: 2;
    content: url(#{$img_dir}/new_top/icon_arw3.svg);
    transition: right .3s;
    transform: translateY(-50%);
  }
  &:hover {
    border: 2px solid #02143a;
    background-color: #02143a;
    color: #fff;
    transition: border-color .2s, background-color .2s .2s;
    &::before {
      left: 0;
    }
    &::after {
      right: 23px;
      content: url(#{$img_dir}/new_top/icon_arw2.svg);
    }
  }
  .txt {
    position: relative;
    z-index: 2;
  }
}

/* =================================
 page
================================= */
body {
  color: $color_2;
  font-family: "Noto Sans JP", "ヒラギノ角ゴ ProN W3", Hiragino Kaku Gothic ProN, Meiryo, sans-serif;
  &._open {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: hidden;
  }
}

.l-header2 {
  z-index: 99;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #fff;

  @include pc_tab {
    position: fixed;
    height: 80px;
    box-shadow: 0 0 16px rgba(0, 0, 0, .1);
  }
  @include sp {
    position: relative;
    height: 60px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, .1);
  }
  .l-header2_inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .header2_logo {
    @include pc_tab {
      padding: 0 0 0 10px;
    }
    .logo_link {
      display: flex;
      align-items: center;
      text-decoration: none;
    }
    .logo_txt {
      color: #02143a;
    }
    .logo_img {
      margin: 0 15px 0 0;
      width: 60px;
      height: 60px;
      background-color: #02143a;
    }
  }
  .header2_nav {
    display: flex;
    align-items: center;

    @include sp {
      display: none;
    }
    .nav_list {
      display: flex;
      align-items: center;
      .nav_item + .nav_item {
        border-left: 1px solid #f2f2f2;
      }
      .nav_link {
        position: relative;
        display: flex;
        align-items: center;
        padding: 0 15px;
        height: 40px;
        color: $color_1;
        text-decoration: none;
        &::before {
          position: absolute;
          bottom: -20px;
          left: calc(50% - 24px);
          display: block;
          width: 0;
          height: 100%;
          border-bottom: 3px solid #365190;
          content: "";
          transition: width .3s;
        }
        &:hover {
          color: #365190;
          &::before {
            width: 47px;
          }
        }
      }
    }
    .nav_btn1,
    .nav_btn2 {
      position: relative;
      display: flex;
      overflow: hidden;
      align-items: center;
      justify-content: center;
      min-width: 160px;
      width: 160px;
      height: 80px;
      text-decoration: none;
      &::before {
        position: absolute;
        top: 0;
        left: calc(-100% - 11px);
        z-index: 1;
        width: calc(100% + 11px);
        height: 100%;
        background-color: #365190;
        content: "";
        transition: left .3s;

        clip-path: polygon(0 100%, 0% 0%, 100% 0%, 94% 100%);
      }
      &:hover {
        &::before {
          left: 0;
        }
      }
      .txt {
        position: relative;
        z-index: 2;
      }
    }
    .nav_btn1 {
      background-color: #ffd800;
      color: #242424;
      &:hover {
        color: #fff;
        .txt {
          &::before {
            content: url(#{$img_dir}/new_top/icon_tel2.svg);
          }
          &::after {
            right: -34px;
            content: url(#{$img_dir}/new_top/icon_arw2.svg);
          }
        }
      }
      .txt {
        &::before {
          position: absolute;
          top: 2px;
          left: -27px;
          content: url(#{$img_dir}/new_top/icon_tel.svg);
        }
        &::after {
          position: absolute;
          top: -4px;
          right: -29px;
          content: url(#{$img_dir}/new_top/icon_arw1.svg);
          transition: right .3s;
        }
      }
    }
    .nav_btn2 {
      background-color: #02143a;
      color: #fff;
      &:hover {
        .txt {
          &::after {
            right: -34px;
          }
        }
      }
      .txt {
        margin: 0 0 0 15px;
        &::before {
          position: absolute;
          top: 3px;
          left: -40px;
          content: url(#{$img_dir}/new_top/icon_mail.svg);
        }
        &::after {
          position: absolute;
          top: -4px;
          right: -29px;
          content: url(#{$img_dir}/new_top/icon_arw2.svg);
          transition: right .3s;
        }
      }
    }
  }
}

.sp-header2_nav {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;

  @include pc_tab {
    display: none;
  }
  .sp-header2_hamburger {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    background-color: #365190;
  }
  .sp-nav {
    position: fixed;
    top: 0;
    left: 100%;
    display: block;
    overflow-y: auto;
    padding: 0 0 40px;
    width: 100%;
    height: 100vh;
    height: -webkit-fill-available;
    background: rgba(2, 20, 58, .95);
    transition: left .3s;
    &._open {
      left: 0;
    }
    .sp-header2_hamburger_close {
      position: fixed;
      top: 0;
      right: 0;
      display: none;
      &._open {
        display: block;
      }
    }
    .nav_list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 15px 15px 0;
      .nav_item {
        width: calc(50% - 7px);
        border-bottom: 1px solid rgba(255, 255, 255, .2);
        .nav_link {
          display: block;
          padding: 15px 0;
          color: #fff;
          text-decoration: none;
        }
      }
    }
    .btn_wrap {
      display: flex;
      justify-content: center;
      margin: 40px 0;
    }
    .nav_btn1,
    .nav_btn2 {
      position: relative;
      display: flex;
      overflow: hidden;
      align-items: center;
      justify-content: center;
      width: 45.3vw;
      height: 80px;
      text-decoration: none;
      &::before {
        position: absolute;
        top: 0;
        left: calc(-100% - 11px);
        z-index: 1;
        width: calc(100% + 11px);
        height: 100%;
        background-color: #365190;
        content: "";
        transition: left .3s;

        clip-path: polygon(0 100%, 0% 0%, 100% 0%, 94% 100%);
      }
      .txt {
        position: relative;
        z-index: 2;
      }
    }
    .nav_btn1 {
      background-color: #ffd800;
      color: #242424;
      .txt {
        &::before {
          position: absolute;
          top: 2px;
          left: -27px;
          content: url(#{$img_dir}/new_top/icon_tel.svg);
        }
        &::after {
          position: absolute;
          top: -4px;
          right: -29px;
          content: url(#{$img_dir}/new_top/icon_arw1.svg);
          transition: right .3s;
        }
      }
    }
    .nav_btn2 {
      margin: 0 0 0 5px;
      background-color: #365190;
      color: #fff;
      .txt {
        margin: 0 0 0 15px;
        &::before {
          position: absolute;
          top: 3px;
          left: -40px;
          content: url(#{$img_dir}/new_top/icon_mail.svg);
        }
        &::after {
          position: absolute;
          top: -4px;
          right: -29px;
          content: url(#{$img_dir}/new_top/icon_arw2.svg);
          transition: right .3s;
        }
      }
    }
    .sns_wrap {
      display: flex;
      justify-content: center;
      .sns_link {
        & + .sns_link {
          margin-left: 20px;
        }
      }
    }
  }
}

.p-top2_mv {
  position: relative;
  z-index: 0;
  width: 100%;
  background-position: center;
  background-size: cover;

  @include sp {
    margin: 0 0 19.7vw;
    height: 112.3vw;
    background-image: url(#{$img_dir}/new_top/sp/mv_bg.jpg);
  }
  @include pc_tab {
    padding: 80px 0 0;
    height: calc(55.3vw + 80px);
    background-image: url(#{$img_dir}/new_top/mv_bg.jpg);
  }
  .mv_txt_wrap {
    position: absolute;

    @include sp {
      top: 15.5vw;
      left: 15px;
      width: 64.5vw;
      height: 62vw;
    }
    @include pc_tab {
      top: calc(6.1vw + 80px);
      left: 6.8vw;
      width: 37.6vw;
      height: 35.5vw;
    }
    .mv_txt {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      transition: clip 1.2s 2s ease-out;

      @include sp {
        clip: rect(0 0 62vw 0);
      }
      @include pc_tab {
        clip: rect(0 0 35.5vw 0);
      }
      &.inview {
        @include sp {
          clip: rect(0 64.5vw 62vw 0);
        }
        @include pc_tab {
          clip: rect(0 37.6vw 35.5vw 0);
        }
      }
    }
    .mv_txt_marker {
      position: absolute;
      z-index: 0;
      transition: clip 1.2s 2s ease-out;

      @include sp {
        top: 32.5vw;
        left: -3.3vw;
        clip: rect(0 0 7.7vw 0);
        width: 29.9vw;
      }
      @include pc_tab {
        top: 18.3vw;
        left: -2.6vw;
        clip: rect(0 0 4vw 0);
        width: 17vw;
      }
      &.inview {
        @include sp {
          clip: rect(0 29.9vw 7.7vw 0);
        }
        @include pc_tab {
          clip: rect(0 17vw 4vw 0);
        }
      }
    }
  }
  .mv_model_wrap {
    position: absolute;
    right: 0;

    @include sp {
      width: 38.9vw;
      height: 113.1vw;
    }
    @include pc_tab {
      top: 80px;
      width: 51.8vw;
      height: 51.3vw;
    }
    .mv_model {
      position: absolute;
      top: 0;
      left: 25%;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: left 1.3s ease-out, opacity 1.5s ease-out;
      &.inview {
        left: 0;
        opacity: 1;
      }
    }
  }
  .mv_badge_wrap {
    position: absolute;

    @include sp {
      top: 78.1vw;
      left: 50%;
      width: 89.7vw;
      height: 53.9vw;
      transform: translateX(-50%);
    }
    @include pc_tab {
      top: calc(20.6vw + 80px);
      right: 4.5vw;
      width: 40.2vw;
      height: 40.2vw;
    }
    .mv_badge {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      transition: clip 1s 3.5s ease-out;

      @include sp {
        clip: rect(0 0 53.9vw 0);
      }
      @include pc_tab {
        clip: rect(0 0 40.2vw 0);
      }
      &.inview {
        @include sp {
          clip: rect(0 89.7vw 53.9vw 0);
        }
        @include pc_tab {
          clip: rect(0 40.2vw 40.2vw 0);
        }
      }
    }
  }
}

.l-main {
  .sec {
    &._1 {
      position: relative;
      width: 100%;

      @include sp {
        padding: 0 0 60px;
      }
      @include pc_tab {
        margin-top: -80px;
        padding: 0 0 130px;
      }
      .logo {
        position: absolute;
        top: -59px;
        left: -55px;
      }
      .bg_1,
      .bg_2,
      .bg_3 {
        position: absolute;
      }
      .bg_1 {
        z-index: 2;
        width: 100%;
        background: #365190;

        @include sp {
          top: 14px;
          left: 0;
          height: 741px;
          transform: skewY(-16deg);
        }
        @include pc_tab {
          top: 90px;
          left: 0;
          height: 543px;
          transform: skewY(-16deg);
        }
      }
      .bg_2 {
        z-index: 1;
        width: 100%;
        background: #384460;

        @include sp {
          right: 0;
          bottom: 53px;
          height: 518px;
          transform: skewY(16deg);
        }
        @include pc_tab {
          top: 90px;
          right: 0;
          clip: rect(0 100vw 543px 50vw);
          height: 543px;
          transform: skewY(16deg);
          @media screen and (max-width: 1240px) {
            clip: rect(0 1500px 543px 620px);
          }
        }
      }
      .bg_3 {
        width: 100%;
        height: 100%;

        @include sp {
          top: 0;
          z-index: 3;
          background-image: url(#{$img_dir}/new_top/sp/sec1_bg.png);
          background-position: top left;
          background-size: cover;
        }
        @include pc_tab {
          top: 9px;
          left: -353px;
          z-index: 3;
          background-image: url(#{$img_dir}/new_top/sec1_bg.png);
          background-position: top center;
          background-size: 1412px 740px;
        }
      }
      .block {
        position: relative;
        z-index: 4;
        display: flex;
        flex-wrap: wrap;

        @include sp {
          flex-direction: column-reverse;
          margin: calc(19.7vw + 60px) 0 0;
          padding: 0 15px;
        }
        @include pc_tab {
          margin: 70px auto 0;
          max-width: 1200px;
          width: 100%;
        }
        .txt_wrap {
          @include sp {
            width: 100%;
          }
          @include pc_tab {
            margin: 0 60px 0 0;
            padding: 0 0 0 55px;
            width: 576px;
          }
          .ttl {
            @include sp {
              margin: 0 0 20px;
            }
            @include pc_tab {
              margin: 0 0 30px;
            }
          }
        }
        .img_wrap {
          @include sp {
            margin: 0 0 30px;
            width: 100%;
          }
          @include pc_tab {
            width: calc(100% - 636px);
          }
        }
      }
      .c-nbtn_wrap {
        @include sp {
          margin: 40px 0 0;
        }
        @include pc_tab {
          margin: 18px 0 0;
        }
      }
    }
    &._2 {
      position: relative;
      z-index: 2;

      @include sp {
        padding: 0 0 60px;
      }
      @include pc_tab {
        padding: 0 0 80px;
      }
      .ttl {
        @include sp {
          margin: 0 6px;
        }
        .img {
          @include sp {
            margin: 6px 0 0;
          }
          @include pc_tab {
            margin: 10px 0 0;
          }
        }
      }
      .badge {
        @include sp {
          margin: 10px 0;
        }
        @include pc_tab {
          margin: 25px 0 30px;
        }
      }
      .c-note_wrap {
        margin: 0 auto;
        max-width: 1067px;

        @include sp {
          padding: 0 15px;
        }
      }
      .c-note {
        position: relative;
        padding-left: 19px;
        &::before {
          position: absolute;
          top: 0;
          left: 0;
          content: "※";
        }
      }
      .c-nbtn_wrap {
        @include sp {
          margin: 30px 0 0;
        }
        @include pc_tab {
          margin: 40px 0 0;
        }
      }
    }
    &._3 {
      @include sp {
        padding: 60px 15px;
        background-image: url(#{$img_dir}/new_top/sp/sec3_bg.png);
        background-position: top left;
        background-size: cover;
      }
      @include pc_tab {
        margin: 0 auto;
        padding: 80px 15px;
        max-width: 1200px;
        background-image: url(#{$img_dir}/new_top/sec3_bg.png);
        background-position: top left;
        background-size: cover;
      }
      .label {
        display: block;
        width: fit-content;

        clip-path: polygon(0% 100%, 7px 0%, 100% 0%, calc(100% - 7px) 100%);
        @include sp {
          margin: 0 auto 10px;
          padding: 5px 20px;
        }
        @include pc_tab {
          margin: 0 auto 20px;
          padding: 5px 30px;
        }
      }
      .youtube {
        max-width: 812px;

        @include sp {
          margin: 30px auto 0;
        }
        @include pc_tab {
          margin: 35px auto 0;
        }
        .youtube_inner {
          position: relative;
          padding-top: 56.25%;
        }

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
    &._4 {
      position: relative;

      @include sp {
        padding: 60px 15px;
      }
      @include pc_tab {
        margin: 0 auto;
        padding: 80px 15px;
      }
      .ttl {
        position: relative;
        z-index: 1;

        @include sp {
          margin: 0 0 30px;
        }
        @include pc_tab {
          margin: 0 0 50px;
        }
      }
      .bg_1 {
        position: absolute;
        z-index: 0;
        width: 100%;
        background: #f4f7ff;

        @include sp {
          top: 154px;
          left: 0;
          height: 741px;
          transform: skewY(-16deg);
        }
        @include pc_tab {
          top: 227px;
          left: 0;
          height: 543px;
          transform: skewY(-16deg);
        }
      }
      .block {
        position: relative;
        z-index: 2;
        display: flex;
        margin: 0 auto;
        max-width: 1200px;

        @include sp {
          flex-direction: column-reverse;
        }
        .txt_wrap {
          max-width: 590px;

          @include pc_tab {
            margin-right: 50px;
          }
          .ttl {
            @include sp {
              margin-bottom: 16px;
            }
            @include pc_tab {
              display: flex;
              align-items: center;
              margin-bottom: 10px;
            }
          }
          .label {
            clip-path: polygon(0% 100%, 4% 0%, 100% 0%, 96% 100%);
            @include sp {
              display: block;
              margin: 0 auto 10px;
              padding: 5px 20px;
              width: fit-content;
            }
            @include pc_tab {
              display: inline-block;
              margin: 0 15px 0 0;
              padding: 0 15px;
              height: 26px;
              line-height: 26px;
            }
          }
          .c-nbtn_wrap {
            @include sp {
              margin: 20px auto 0;
            }
            @include pc_tab {
              margin: 30px auto 0;
            }
            .c-nbtn_1 {
              @include pc_tab {
                margin: 0;
              }
            }
          }
        }
        .img_wrap {
          max-width: 560px;

          @include sp {
            margin: 0 auto 30px;
          }
        }
        &:nth-of-type(even) {
          @include pc_tab {
            flex-direction: row-reverse;
            .txt_wrap {
              margin-right: 0;
            }
            .img_wrap {
              margin-right: 50px;
            }
          }
        }
        & + .block {
          @include sp {
            margin: 40px auto 0;
          }
          @include pc_tab {
            margin: 95px auto 0;
          }
        }
      }
    }
    &._5 {
      position: relative;
      z-index: 2;
      .ttl {
        .marker {
          position: relative;
          &::before {
            position: absolute;
            z-index: -1;

            @include sp {
              bottom: 17px;
              left: -7px;
              width: 84px;
              height: 17px;
              content: url(#{$img_dir}/new_top/sp/sec_5_marker.svg);
            }
            @include pc_tab {
              bottom: 13px;
              left: -20px;
              width: 123px;
              height: 25px;
              content: url(#{$img_dir}/new_top/sec_5_marker.svg);
            }
          }
        }
      }
      .list {
        display: flex;
        align-items: stretch;
        flex-wrap: wrap;
        max-width: 1200px;

        @include sp {
          margin: 30px 15px 0;
        }
        @include pc_tab {
          margin: 50px auto 0;
        }
        .item {
          display: flex;
          flex-wrap: wrap;

          @include sp {
            flex-direction: column-reverse;
            width: calc(50% - 7.5px);
          }
          @include pc_tab {
            width: calc(25% - 18px);
          }
          .link {
            display: flex;
            flex-wrap: wrap;
            box-shadow: 3px 3px #f2f2f2;
            text-decoration: none;

            @include sp {
              flex-direction: column-reverse;
              border-radius: 6px;
            }
            @include pc_tab {
              border-radius: 10px;
              opacity: 1;
              transition: opacity .3s;
              &:hover {
                opacity: .7;
              }
            }
          }
          .txt_wrap {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;

            @include sp {
              height: 108px;
              border-radius: 0 0 6px 6px;
            }
            @include pc_tab {
              height: 125px;
              border-radius: 10px 10px 0 0;
            }
          }
          .img_wrap {
            width: 100%;
            .img {
              width: 100%;
            }
          }
          & + .item {
            @include pc_tab {
              margin-left: 24px;
            }
          }
          &:nth-of-type(2n) {
            @include sp {
              margin-left: 15px;
            }
          }
          &:nth-of-type(n + 2) {
            @include sp {
              margin-top: 15px;
            }
          }
        }
      }
    }
    &._6 {
      position: relative;
      z-index: 1;
      width: 100%;

      @include sp {
        padding: 60px 0 0;
      }
      @include pc_tab {
        padding: 100px 0 0;
      }
      .logo {
        position: relative;
        top: 12px;
        left: -103px;
      }
      .bg_1 {
        position: absolute;
        z-index: 0;
        width: 100%;
        background: #ebf1ff;

        @include sp {
          top: 485px;
          left: 0;
          height: 780px;
          transform: skewY(16deg);
        }
        @include pc_tab {
          top: 32vw;
          left: 0;
          height: 543px;
          transform: skewY(16deg);
        }
      }
      .bg_2 {
        position: absolute;
        width: 100%;
        height: 100%;

        @include sp {
          top: 0;
          z-index: 3;
          height: 154.1vw;
          background-image: url(#{$img_dir}/new_top/sp/sec6_bg.png);
          background-position: top left;
          background-size: 100vw 154.1vw;
        }
        @include pc_tab {
          bottom: 0;
          z-index: 3;
          background-image: url(#{$img_dir}/new_top/sec6_bg.png);
          background-position: bottom left;
          background-size: 60.5vw 32.6vw;
        }
      }
      .block {
        position: relative;
        z-index: 4;
        display: flex;
        flex-wrap: wrap;

        @include sp {
          flex-direction: column-reverse;
        }
        @include pc_tab {
          width: 100%;
          height: 40.7vw;
        }
        .txt_wrap {
          @include sp {
            position: relative;
            z-index: 4;
            margin-top: -7vw;
            padding: 0 15px;
            width: 100%;
          }
          @include pc_tab {
            position: relative;
            z-index: 4;
            display: flex;
            align-items: flex-end;
            justify-content: flex-start;
            margin: 0 auto;
            width: 1200px;
          }
          .txt {
            @include pc_tab {
              padding: 0 0 10.9vw 60px;
              width: 500px;
            }
          }
          .ttl {
            @include sp {
              margin: 0 0 20px;
            }
            @include pc_tab {
              margin: 0 0 30px;
            }
          }
        }
        .img_wrap {
          @include sp {
            position: relative;
            z-index: 2;
            width: 100%;
          }
          @include pc_tab {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 1;
            width: 61vw;
          }
          .img {
            @include pc_tab {
              width: 100%;
            }
          }
        }
      }
      .c-nbtn_wrap {
        @include sp {
          margin: 40px 0 0;
        }
        @include pc_tab {
          margin: 18px 0 0;
        }
      }
    }
    &._7 {
      position: relative;
      z-index: 1;

      @include sp {
        padding: 60px 15px 0;
      }
      @include pc_tab {
        margin: 0 auto;
        padding: 90px 0 0;
      }
      .bg_1 {
        position: absolute;
        z-index: 0;
        width: 100%;
        background: #ebf1ff;

        @include sp {
          bottom: -208px;
          left: 0;
          height: 780px;
          transform: skewY(-16deg);
        }
        @include pc_tab {
          bottom: 0;
          left: 0;
          height: 543px;
          transform: skewY(-16deg);
        }
      }
      & > .ttl {
        @include sp {
          margin: 0 auto 30px;
        }
        @include pc_tab {
          margin: 0 auto 50px;
        }
      }
      .label {
        display: block;
        width: fit-content;

        clip-path: polygon(0% 100%, 7px 0%, 100% 0%, calc(100% - 7px) 100%);
        @include sp {
          margin: 0 auto 10px;
          padding: 5px 20px;
        }
        @include pc_tab {
          margin: 0 auto 20px;
          padding: 5px 30px;
        }
      }
      .block {
        position: relative;
        z-index: 2;
        display: flex;
        margin: 0 auto;
        max-width: 1200px;

        @include sp {
          flex-direction: column-reverse;
        }
        .txt_wrap {
          max-width: 590px;

          @include pc_tab {
            margin-right: 50px;
            padding: 0 0 0 30px;
          }
          .ttl {
            display: flex;
            align-items: center;
            word-break: break-all;

            @include sp {
              margin-bottom: 16px;
            }
            @include pc_tab {
              margin-bottom: 20px;
            }
            .icon {
              @include sp {
                margin-right: 26px;
              }
              @include pc_tab {
                margin-right: 30px;
              }
            }
            .txt {
              @include sp {
                width: calc(100% - 78px);
              }
              @include pc_tab {
                width: calc(100% - 96px);
              }
            }
          }
          .label {
            clip-path: polygon(0% 100%, 4% 0%, 100% 0%, 96% 100%);
            @include sp {
              display: block;
              margin: 0 auto 10px;
              padding: 5px 20px;
              width: fit-content;
            }
            @include pc_tab {
              display: inline-block;
              margin: 0 15px 0 0;
              padding: 0 15px;
              height: 26px;
              line-height: 26px;
            }
          }
          .c-nbtn_wrap {
            @include sp {
              margin: 20px auto 0;
            }
            @include pc_tab {
              margin: 30px auto 0;
            }
            .c-nbtn_1 {
              @include pc_tab {
                margin: 0;
              }
            }
          }
        }
        .img_wrap {
          max-width: 560px;

          @include sp {
            margin: 0 auto 10px;
          }
        }
        &:nth-of-type(even) {
          @include pc_tab {
            flex-direction: row-reverse;
            .txt_wrap {
              margin-right: 0;
              padding: 0 30px 0 0;
            }
            .img_wrap {
              margin-right: 50px;
            }
          }
        }
        & + .block {
          @include sp {
            margin: 40px auto 0;
          }
          @include pc_tab {
            margin: 70px auto 0;
          }
        }
      }
    }
    &._8 {
      position: relative;
      z-index: 1;

      @include sp {
        padding: 60px 0 0;
      }
      @include pc_tab {
        padding: 100px 0 0;
      }
      .label {
        display: block;
        width: fit-content;

        clip-path: polygon(0% 100%, 1.5% 0%, 100% 0%, 98.5% 100%);
        @include sp {
          margin: 0 auto 10px;
          padding: 5px 20px;
        }
        @include pc_tab {
          margin: 0 auto 20px;
          padding: 5px 30px;
        }
      }
      .block {
        width: 100%;

        @include sp {
          padding: 50px 15px;
        }
        @include pc_tab {
          padding: 50px 0;
        }
        .block_inner {
          position: relative;
          z-index: 2;
          display: flex;
          margin: 0 auto;
          max-width: 1200px;

          @include sp {
            flex-direction: column-reverse;
          }
          .img_wrap {
            max-width: 570px;

            @include sp {
              margin: 0 auto 30px;
            }
          }
        }
        .txt_wrap {
          max-width: 560px;

          @include pc_tab {
            margin-right: 70px;
            padding: 0 0 0 50px;
          }
          .ttl {
            @include sp {
              margin-bottom: 16px;
            }
            @include pc_tab {
              margin-bottom: 20px;
            }
          }
          .label {
            display: block;
            width: fit-content;

            clip-path: polygon(0% 100%, 4% 0%, 100% 0%, 96% 100%);
            @include sp {
              margin: 0 0 5px;
              padding: 0 20px;
              width: 120px;
              height: 28px;
              text-align: center;
              line-height: 28px;
            }
            @include pc_tab {
              margin: 0 0 10px;
              padding: 0 36px;
              height: 39px;
              line-height: 39px;
            }
          }
          .c-nbtn_wrap {
            @include sp {
              margin: 20px auto 0;
            }
            @include pc_tab {
              margin: 30px auto 0;
            }
            .c-nbtn_1 {
              @include pc_tab {
                margin: 0;
                width: 340px;
              }
              .txt {
                @include pc_tab {
                  left: -9px;
                }
              }
            }
          }
        }
        &:nth-of-type(even) {
          .block_inner {
            @include pc_tab {
              flex-direction: row-reverse;
              .txt_wrap {
                margin-right: 0;
                padding: 0 50px 0 0;
              }
              .img_wrap {
                margin-right: 70px;
              }
            }
          }
        }
        & + .block {
          .block_inner {
            @include sp {
              margin: 40px auto 0;
            }
            @include pc_tab {
              margin: 70px auto 0;
            }
          }
        }
      }
    }
    &._9 {
      position: relative;
      z-index: 1;

      @include sp {
        padding: 60px 15px 0;
      }
      @include pc_tab {
        margin: 0 auto;
        padding: 90px 0 0;
      }
      .label {
        display: block;
        width: fit-content;

        clip-path: polygon(0% 100%, 7px 0%, 100% 0%, calc(100% - 7px) 100%);
        @include sp {
          margin: 0 auto 10px;
          padding: 5px 20px;
        }
        @include pc_tab {
          margin: 0 auto 20px;
          padding: 5px 30px;
        }
      }
      .list {
        @include sp {
          margin: 30px 0 0;
        }
        @include pc_tab {
          display: flex;
          justify-content: space-between;
          margin: 50px auto 0;
          max-width: 1200px;
        }
        .item {
          @include pc_tab {
            width: calc(33.33% - 10px);
          }
        }
      }
      .c-nbtn_wrap {
        @include sp {
          margin: 30px 0 0;
        }
        @include pc_tab {
          margin: 40px 0 0;
        }
      }
    }
    &._10 {
      position: relative;
      z-index: 0;

      @include sp {
        padding: 60px 15px 0;
      }
      @include pc_tab {
        margin: 0 auto;
        padding: 100px 0 0;
      }
      .bg_1 {
        position: absolute;
        z-index: -1;
        width: 100%;
        background: #ebf1ff;

        @include sp {
          bottom: 17px;
          left: 0;
          height: 780px;
          transform: skewY(16deg);
        }
        @include pc_tab {
          bottom: 17px;
          left: 0;
          height: 543px;
          transform: skewY(16deg);
        }
      }
      .label {
        display: block;
        width: fit-content;

        clip-path: polygon(0% 100%, 7px 0%, 100% 0%, calc(100% - 7px) 100%);
        @include sp {
          margin: 0 auto 10px;
          padding: 5px 20px;
        }
        @include pc_tab {
          margin: 0 auto 20px;
          padding: 5px 30px;
        }
      }
      .list {
        @include sp {
          margin: 30px 0 0;
        }
        @include pc_tab {
          display: flex;
          justify-content: space-between;
          margin: 40px auto 0;
          max-width: 1200px;
        }
        .item {
          @include pc_tab {
            width: calc(50% - 20px);
          }
          .img_wrap {
            .img {
              width: 100%;
            }
          }
          .txt_wrap {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            margin: 15px 0 0;
            border-radius: 10px;
            background: #fff;

            @include sp {
              padding: 15px 10px;
            }
            @include pc_tab {
              padding: 20px 30px;
            }
            .case {
              @include sp {
                margin: 5px 10px 0 0;
                width: 43px;
              }
              @include pc_tab {
                margin: 5px 20px 0 0;
                width: 43px;
              }
            }
            .txt {
              @include sp {
                width: calc(100% - 53px);
              }
              @include pc_tab {
                width: calc(100% - 63px);
              }
            }
          }
          & + .item {
            @include sp {
              margin-top: 30px;
            }
          }
        }
      }
      .c-nbtn_wrap {
        @include sp {
          margin: 30px 0 0;
        }
        @include pc_tab {
          margin: 40px 0 0;
        }
      }
    }
    &._11 {
      position: relative;
      z-index: 0;

      @include sp {
        padding: 60px 15px;
      }
      @include pc_tab {
        margin: 0 auto;
        padding: 100px 0 80px;
      }
      .label {
        display: block;
        width: fit-content;

        clip-path: polygon(0% 100%, 7px 0%, 100% 0%, calc(100% - 7px) 100%);
        @include sp {
          margin: 0 0 10px;
          padding: 5px 20px;
        }
        @include pc_tab {
          margin: 0 0 20px;
          padding: 5px 30px;
        }
      }
      .block {
        @include pc_tab {
          display: flex;
          align-items: flex-start;
          margin: 0 auto;
          max-width: 1200px;
        }
        .left {
          @include pc_tab {
            margin-right: 60px;
          }
          .ttl {
            @include sp {
              margin-bottom: 30px;
            }
          }
        }
        .item {
          & + .item {
            border-top: 1px solid #e6e6e6;
          }
          .link {
            color: #242424;
            text-decoration: none;
            .category {
              display: inline-block;
              padding: 0 15px;
              border-radius: 14px;
              background-color: #365190;
              color: #fff;

              @include sp {
                margin: 0 0 0 10px;
                line-height: 23px;
              }
              @include pc_tab {
                margin: 0 0 0 15px;
                line-height: 27px;
              }
            }
            .body {
              display: flex;
              align-items: flex-start;

              @include sp {
                margin: 12px 0 0;
              }
              @include pc_tab {
                margin: 10px 0 0;
              }
              .new {
                display: inline-block;
                margin: 5px 5px 0 0;
                padding: 0 10px;
                width: 54px;
                border: 1px solid #365190;
                color: #365190;
                word-break: keep-all;
                line-height: 20px;
              }
            }
            &:hover {
              @include pc_tab {
                color: #365190;
              }
            }
          }
          & + .item {
            margin: 30px 0 0;
            padding: 30px 0 0;
          }
        }
        .c-nbtn_wrap {
          @include sp {
            margin: 45px 0 0;
          }
          @include pc_tab {
            margin: 30px 0 0;
          }
        }
        .c-nbtn_1 {
          margin: 0;
        }
      }
    }
    &._12 {
      position: relative;
      z-index: 1;

      @include sp {
        padding: 60px 15px;
      }
      @include pc_tab {
        margin: 0 auto;
        padding: 80px 0;
      }
      .label {
        display: block;
        width: fit-content;

        clip-path: polygon(0% 100%, 7px 0%, 100% 0%, calc(100% - 7px) 100%);
        @include sp {
          margin: 0 auto 10px;
          padding: 5px 20px;
        }
        @include pc_tab {
          margin: 0 auto 20px;
          padding: 5px 30px;
        }
      }
      .list {
        @include sp {
          margin: 30px 0 0;
        }
        @include pc_tab {
          display: flex;
          justify-content: space-between;
          margin: 50px auto 0;
          max-width: 1200px;
        }
        .item {
          @include pc_tab {
            width: calc(33.33% - 30px);
          }
          .link {
            display: block;
            color: #242424;
            text-decoration: none;
            opacity: 1;
            transition: opacity .3s;
            &:hover {
              @include pc_tab {
                opacity: .8;
                .img {
                  transform: scale(1.1);
                }
              }
            }
            .head {
              display: flex;
              justify-content: space-between;

              @include sp {
                margin: 20px 0 10px;
              }
              @include pc_tab {
                margin: 25px 0 10px;
              }
            }
            .category {
              display: inline-block;
              padding: 0 15px;
              border-radius: 14px;
              background-color: #365190;
              color: #fff;

              @include sp {
                margin: 0 10px 0 0;
                height: 23px;
                line-height: 23px;
              }
              @include pc_tab {
                margin: 0 15px 0 0;
                line-height: 27px;
              }
            }
            .date {
              color: #999;
            }
            .ttl {
              margin: 0 0 10px;
            }
            .body {
              .omit {
                display: -webkit-box;
                overflow: hidden;
                -webkit-box-orient: vertical;
                max-height: 6em;
                -webkit-line-clamp: 4;
                line-height: 1.5em;
              }
            }
          }
          .img_wrap {
            overflow: hidden;
            .img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              transition: transform .3s;
            }
          }
          & + .item {
            @include sp {
              margin-top: 30px;
            }
          }
        }
      }
      .c-nbtn_wrap {
        @include sp {
          margin: 30px 0 0;
        }
        @include pc_tab {
          margin: 40px 0 0;
        }
      }
    }
  }
}

.section-top {
  @include sp {
    padding-top: 60px;
    padding-bottom: 40px;
  }
  @include pc_tab {
    padding-bottom: 60px;
  }
}
.p-banner {
  background-color: #fff !important;
}

.l-footer2 {
  background-color: #02143a;
  .l-inner {
    display: flex;
    justify-content: space-between;

    @include sp {
      flex-direction: column-reverse;
      padding: 35px 15px 0;
    }
    @include pc_tab {
      padding: 60px 0;
    }
    .left {
      text-decoration: none;

      @include sp {
        margin-top: 40px;
      }
      @include pc_tab {
        margin-right: 70px;
      }
      .logo_area {
        display: flex;
        align-items: center;
        margin: 0 0 10px;
        .info_logo {
          margin-right: 15px;
          width: 80px;
          height: 80px;
        }
      }
      .info_tel {
        display: block;
        margin: 20px 0 10px;
      }
      .btn_wrap {
        display: flex;
        margin: 30px 0 0;

        @include sp {
          justify-content: center;
        }
      }
      .nav_btn1,
      .nav_btn2 {
        position: relative;
        display: flex;
        overflow: hidden;
        align-items: center;
        justify-content: center;
        height: 80px;
        text-decoration: none;

        @include sp {
          width: calc(50% - 2.5px);
        }
        @include pc_tab {
          min-width: 160px;
          width: 160px;
        }
        &::before {
          position: absolute;
          top: -5%;
          left: calc(-100% - 11px);
          z-index: 1;
          width: calc(100% + 11px);
          height: 110%;
          background-color: #365190;
          content: "";
          transition: left .3s;

          clip-path: polygon(0 100%, 0% 0%, 100% 0%, 94% 100%);
        }
        &:hover {
          &::before {
            left: 0;
          }
        }
        .txt {
          position: relative;
          z-index: 2;
        }
      }
      .nav_btn1 {
        background-color: #ffd800;
        color: #242424;
        &:hover {
          color: #fff;
          .txt {
            &::before {
              content: url(#{$img_dir}/new_top/icon_tel2.svg);
            }
            &::after {
              right: -30px;
              content: url(#{$img_dir}/new_top/icon_arw2.svg);
            }
          }
        }
        .txt {
          &::before {
            position: absolute;
            top: 2px;
            left: -27px;
            content: url(#{$img_dir}/new_top/icon_tel.svg);
          }
          &::after {
            position: absolute;
            top: -4px;
            right: -25px;
            content: url(#{$img_dir}/new_top/icon_arw1.svg);
            transition: right .3s;
          }
        }
      }
      .nav_btn2 {
        background-color: #365190;
        color: #fff;

        @include sp {
          margin-left: 5px;
        }
        @include pc_tab {
          margin-left: 10px;
        }
        &::before {
          background-color: #384460;
        }
        &:hover {
          .txt {
            &::after {
              right: -30px;
            }
          }
        }
        .txt {
          margin: 0 0 0 15px;
          &::before {
            position: absolute;
            top: 3px;
            left: -40px;
            content: url(#{$img_dir}/new_top/icon_mail.svg);
          }
          &::after {
            position: absolute;
            top: -4px;
            right: -25px;
            content: url(#{$img_dir}/new_top/icon_arw2.svg);
            transition: right .3s;
          }
        }
      }
    }
    .right {
      @include sp {
        width: 100%;
      }
      @include pc_tab {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
      }
      .f_nav_pc {
        @include pc_tab {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-end;
        }
        @include sp {
          display: none;
        }
      }
      .f_nav_sp {
        @include sp {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-end;
        }
        @include pc_tab {
          display: none;
        }
      }
      .list {
        @include sp {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }
        .item {
          @include sp {
            width: calc(50% - 7.5px);
            border-bottom: 1px solid rgba(255, 255, 255, .2);
          }
          .link {
            display: block;
            color: #fff;
            text-decoration: none;
            opacity: 1;
            transition: opacity .3s, padding-left .4s;

            @include sp {
              padding: 15px 0;
            }
            @include pc_tab {
              padding: 0 0 10px;
              width: 200px;
            }
            &:hover {
              @include pc_tab {
                padding-left: 7px;
                opacity: .8;
              }
            }
          }
          &:nth-of-type(2n) {
            @include sp {
              margin-left: 15px;
            }
          }
          & + .item {
            @include pc_tab {
              border-top: 1px solid rgba(255, 255, 255, .2);
            }
            .link {
              @include sp {
                padding: 15px 0;
              }
              @include pc_tab {
                padding: 10px 0;
              }
              &:hover {
                @include pc_tab {
                  padding-left: 7px;
                }
              }
            }
          }
        }
        & + .list {
          @include pc_tab {
            margin-left: 30px;
          }
        }
      }
    }
  }
  .sns {
    display: flex;
    width: 100%;
    font-size: 30px;

    @include sp {
      justify-content: center;
      margin-top: 40px;
    }
    @include pc_tab {
      justify-content: flex-end;
    }
    .sns_item {
      .sns_link {
        color: #fff;
        text-decoration: none;
        opacity: 1;
        transition: opacity .3s;
        &:hover {
          @include pc_tab {
            opacity: .7;
          }
        }
      }
      & + .sns_item {
        margin-left: 20px;
      }
    }
  }
  .copy {
    white-space: nowrap;
    font-family: "Open Sans", sans-serif;

    @include sp {
      padding: 40px 0 98px;
    }
    @include pc_tab {
      padding: 30px 0 0;
    }
  }
}

.float {
  @include sp {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 99;
    display: flex;
    padding: 5px;
    width: 100%;
    background-color: rgba(255, 255, 255, .9);
  }
  @include pc_tab {
    display: none;
  }
  .nav_btn1,
  .nav_btn2 {
    position: relative;
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    width: calc(50% - 2.5px);
    height: 50px;
    text-decoration: none;
    &::before {
      position: absolute;
      top: 0;
      left: calc(-100% - 11px);
      z-index: 1;
      width: calc(100% + 11px);
      height: 100%;
      background-color: #365190;
      content: "";
      transition: left .3s;

      clip-path: polygon(0 100%, 0% 0%, 100% 0%, 94% 100%);
    }
    &:hover {
      &::before {
        left: 0;
      }
    }
    .txt {
      position: relative;
      z-index: 2;
    }
  }
  .nav_btn1 {
    background-color: #ffd800;
    color: #242424;
    &:hover {
      color: #fff;
      .txt {
        &::before {
          content: url(#{$img_dir}/new_top/icon_tel2.svg);
        }
        &::after {
          right: -30px;
          content: url(#{$img_dir}/new_top/icon_arw2.svg);
        }
      }
    }
    .txt {
      &::before {
        position: absolute;
        top: 2px;
        left: -27px;
        content: url(#{$img_dir}/new_top/icon_tel.svg);
      }
      &::after {
        position: absolute;
        top: -4px;
        right: -25px;
        content: url(#{$img_dir}/new_top/icon_arw1.svg);
        transition: right .3s;
      }
    }
  }
  .nav_btn2 {
    margin-left: 5px;
    background-color: #365190;
    color: #fff;
    &::before {
      background-color: #384460;
    }
    &:hover {
      .txt {
        &::after {
          right: -30px;
        }
      }
    }
    .txt {
      margin: 0 0 0 15px;
      &::before {
        position: absolute;
        top: 3px;
        left: -40px;
        content: url(#{$img_dir}/new_top/icon_mail.svg);
      }
      &::after {
        position: absolute;
        top: -4px;
        right: -25px;
        content: url(#{$img_dir}/new_top/icon_arw2.svg);
        transition: right .3s;
      }
    }
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
  display: none;
  min-height: 100vh;
  width: 100%;
  height: 100vh;
  height: -webkit-fill-available;
  .m_overlay {
    position: fixed;
    top: 0;
    left: 0;
    min-height: 100vh;
    width: 100%;
    height: 100vh;
    height: -webkit-fill-available;
    background: rgba(2, 20, 58, .4);
  }
  .inner {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    text-align: center;
    transform: translate(-50%,-50%);

    @include sp {
      padding: 40px 15px;
      width: calc(100% - 30px);
      border: 15px solid #ffd700;
    }
    @include pc_tab {
      padding: 87px 20px 72px;
      width: 1000px;
      border: 30px solid #ffd700;
    }
    .ttl {
      display: flex;
      align-items: center;
      justify-content: center;
      .icon {
        margin-right: 10px;

        @include sp {
          width: 27px;
        }
        @include pc_tab {
          width: 35px;
        }
      }
    }
    .list {
      display: flex;
      flex-wrap: wrap;

      @include sp {
        justify-content: center;
        margin: 30px 0 0;
      }
      @include pc_tab {
        margin: 36px 0 0;
        width: 574px;
      }
      .item {
        display: flex;
        align-items: center;
        justify-content: center;

        @include sp {
          max-width: 285px;
          width: 100%;
        }
        @include pc_tab {
          margin-bottom: 15px;
          width: calc(50% - 25px);
        }
        .name {
          display: inline-block;
          margin-right: 10px;
          padding-right: 10px;
          border-right: 1px solid #e6e6e6;
        }
        .c-nbtn_1 {
          width: 100%;
        }
        & + .item {
          @include sp {
            margin-top: 10px;
          }
        }
        &:nth-of-type(even) {
          @include pc_tab {
            margin-left: 50px;
          }
        }
      }
    }
  }
  .modalClose {
    position: absolute;
    color: #fff;

    @include sp {
      top: -15px;
      right: -15px;
    }
    @include pc_tab {
      top: -30px;
      right: -30px;
    }
  }
}

.modal_web {
  .inner {
    @include sp {
      border: 15px solid #02143a;
    }
    @include pc_tab {
      border: 30px solid #02143a;
    }
    .list {
      .item {
        @include sp {
          width: 100%;
        }
        &:nth-of-type(even) {
          @include pc_tab {
            margin-left: 20px;
          }
        }
        .c-nbtn_1 {
          @include pc_tab {
            width: 320px;
          }
        }
      }
    }
  }
}
